import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue';
import NewsItem from '@/views/NewsItem.vue';
import InformationItem from '@/views/InformationItem.vue';
import EventItem from '@/views/EventItem.vue';
import LocationItem from '@/views/LocationItem.vue';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: TabsPage,
    children: [
      {
        path: '/',
        redirect: '/programm'
      },
      {
        path: 'programm',
        component: () => import('@/views/Tab1Page.vue')
      },
      {
        path: 'neuigkeiten',
        component: () => import('@/views/Tab2Page.vue')
      },
      {
        path: 'informationen',
        component: () => import('@/views/Tab3Page.vue')
      },
      {
        path: 'orte',
        component: () => import('@/views/Tab4Page.vue')
      }
    ]
  },
  {
    path: '/neuigkeiten/:newsId',
    name: 'News',
    component: NewsItem,
  },
  {
    path: '/information/:infoId',
    name: 'Information',
    component: InformationItem,
  },
  {
    path: '/programm/:eventId',
    name: 'Programm',
    component: EventItem
  },
  {
    path: '/ort/:locationId',
    name: 'Ort',
    component: LocationItem
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
