<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/informationen"></ion-back-button>
        </ion-buttons>
        <ion-title>Information</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <div v-if="!loading && data">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ data.title }}</ion-card-title>
          </ion-card-header>

          <ion-card-content>
            <div v-html="data.text">
            </div>
          </ion-card-content>
        </ion-card>
      </div>

      <div v-if="loading" class="element-loading">
        <ion-chip>
          <ion-spinner name="circles"></ion-spinner>
          <ion-label>Information wird geladen</ion-label>
        </ion-chip>
      </div>
      <p v-if="error">
        Leider ist ein Fehler aufgetreten.
      </p>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardTitle, IonCardHeader, IonCardContent, IonLabel, IonChip, IonSpinner } from '@ionic/vue';
import { ref, onMounted } from 'vue';
import { mapOutline } from 'ionicons/icons';
import { useRoute } from 'vue-router';

export default {
  name: 'InformationItem',
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard, 
    IonCardTitle, 
    IonCardHeader, 
    IonCardContent, 
    IonLabel, 
    IonChip, 
    IonSpinner
  },
  props: {
  },
  methods: {
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();

    function fetchData(infoId) {
      loading.value = true;
      return fetch(process.env.VUE_APP_API_URL + '/information/' + infoId, {
        method: 'get',
        headers: {
        }
      })
      .then(res => {
        if (!res.ok) {
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        }
        return res.json();
      })
      .then(json => {
        data.value = json;
      })
      .catch(err => {
        error.value = err;
        if (err.json) {
          return err.json.then(json => {
            error.value.message = json.message;
          });
        }
      })
      .then(() => {
        loading.value = false;
      });
    }

    onMounted(() => {
      fetchData(route.params.infoId);
    });

    return {
      data,
      loading,
      error,
      mapOutline
    };
  }
};
</script>