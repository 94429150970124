<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/orte"></ion-back-button>
        </ion-buttons>
        <ion-title>Ort</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <div v-if="!loading && data">
        <ion-card>
          <ion-card-header>
            <ion-card-title>{{ data.name }}</ion-card-title>
            <ion-card-subtitle>{{ data.address }}</ion-card-subtitle>
          </ion-card-header>

          <ion-card-content>
            <img class="location-image" :src="data.imgUrl" />
            <div>{{ data.description }}</div>
            <ion-button expand="block" color="secondary" @click="openGoogleMaps(data.address)">
              <ion-icon slot="start"></ion-icon>
              In Google-Maps öffnen
            </ion-button>
          </ion-card-content>
        </ion-card>
      </div>

      <div v-if="loading" class="element-loading">
        <ion-chip>
          <ion-spinner name="circles"></ion-spinner>
          <ion-label>Ort wird geladen</ion-label>
        </ion-chip>
      </div>
      <p v-if="error">
        Leider ist ein Fehler aufgetreten.
      </p>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonBackButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonCard, IonCardTitle, IonCardHeader, IonCardSubtitle, IonCardContent, IonLabel, IonChip, IonSpinner } from '@ionic/vue';
import { ref, onMounted } from 'vue';
import { mapOutline } from 'ionicons/icons';
import { useRoute } from 'vue-router';

export default {
  name: 'InformationItem',
  components: {
    IonBackButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard, 
    IonCardTitle, 
    IonCardHeader, 
    IonCardContent, 
    IonCardSubtitle,
    IonLabel, 
    IonChip, 
    IonSpinner
  },
  props: {
  },
  methods: {
    openGoogleMaps(address){
      window.open('https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address) + '', '_blank');
    }
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();

    function fetchData(locationId) {
      loading.value = true;
      return fetch(process.env.VUE_APP_API_URL + '/location/' + locationId, {
        method: 'get',
        headers: {
        }
      })
      .then(res => {
        if (!res.ok) {
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        }
        return res.json();
      })
      .then(json => {
        data.value = json;
      })
      .catch(err => {
        error.value = err;
        if (err.json) {
          return err.json.then(json => {
            error.value.message = json.message;
          });
        }
      })
      .then(() => {
        loading.value = false;
      });
    }

    onMounted(() => {
      fetchData(route.params.locationId);
    });

    return {
      data,
      loading,
      error,
      mapOutline
    };
  }
};
</script>
<style scoped>
.location-image {
  max-height: 256px;
}
</style>