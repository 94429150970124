<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button default-href="/programm"></ion-back-button>
        </ion-buttons>
        <ion-title>Programmpunkt</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>

      <div v-if="!loading && data">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-md>
              <ion-card>
                <ion-card-header>
                  <ion-card-title>{{ data.name }}</ion-card-title>
                  <ion-card-subtitle>{{ formatDate(data.date) }} Uhr</ion-card-subtitle>
                </ion-card-header>

                <ion-card-content>
                  <p v-if="data.description">
                    {{ data.description }}
                  </p>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="12" size-md>
              <ion-card>
                <ion-card-header>
                  <ion-card-title>{{ data.location.name }}<span v-if="data.locationText"> im {{ data.locationText }}</span></ion-card-title>
                  <ion-card-subtitle>{{ data.location.address }}</ion-card-subtitle>
                </ion-card-header>

                <ion-card-content>
                  <img class="location-image" :src="data.location.imgUrl" />
                  <ion-button expand="block" color="secondary" @click="openGoogleMaps(data.location.address)">
                    <ion-icon slot="start"></ion-icon>
                    In Google-Maps öffnen
                  </ion-button>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>

      <div v-if="loading" class="element-loading">
        <ion-chip>
          <ion-spinner name="circles"></ion-spinner>
          <ion-label>Programmpunkt wird geladen</ion-label>
        </ion-chip>
      </div>
      <p v-if="error">
        Leider ist ein Fehler aufgetreten.
      </p>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonBackButton, IonButton, IonButtons, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonIcon, IonLabel, IonCardTitle, IonCard, IonCardHeader, IonCardSubtitle, IonCardContent, IonSpinner, IonChip, IonGrid, IonRow, IonCol } from '@ionic/vue';
import { ref, onMounted } from 'vue';
import { mapOutline } from 'ionicons/icons';
import { useRoute } from 'vue-router';
import dayjs from 'dayjs';
import 'dayjs/locale/de';

export default {
  name: 'EventItem',
  components: {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonIcon, 
    IonLabel, 
    IonCardTitle, 
    IonCard, 
    IonCardHeader, 
    IonCardSubtitle, 
    IonCardContent, 
    IonSpinner, 
    IonChip, 
    IonGrid, 
    IonRow, 
    IonCol
  },
  props: {
  },
  methods: {
    formatDate(dateString) {
        const date = dayjs(dateString);
        return date.locale('de').format('dddd, DD.MM.YYYY HH:mm');
    },
    openGoogleMaps(address){
      window.open('https://www.google.com/maps/search/?api=1&query=' + encodeURIComponent(address) + '', '_blank');
    }
  },
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();

    function fetchData(eventId) {
      loading.value = true;
      return fetch(process.env.VUE_APP_API_URL + '/eventItem/' + eventId, {
        method: 'get',
        headers: {
        }
      })
      .then(res => {
        if (!res.ok) {
          const error = new Error(res.statusText);
          error.json = res.json();
          throw error;
        }
        return res.json();
      })
      .then(json => {
        data.value = json;
      })
      .catch(err => {
        error.value = err;
        if (err.json) {
          return err.json.then(json => {
            error.value.message = json.message;
          });
        }
      })
      .then(() => {
        loading.value = false;
      });
    }

    onMounted(() => {
      fetchData(route.params.eventId);
    });

    return {
      data,
      loading,
      error,
      mapOutline
    };
  }
};
</script>
<style scoped>
.location-image {
  max-height: 256px;
}
</style>